<template>
	<div class="settings">
		<div class="title">{{ user.userName }}</div>
		<div class="memberSince right">
			Joined: {{ user.createdAt | just_date }}
		</div>
		<div class="header">Your Groups</div>
		<div class="groupList">
			<div
				class="group"
				v-for="group in groups"
				v-bind:key="'group:' + group.id"
			>
				<div class="groupName" @click="editGroup(group)">{{ group.name }}</div>
				<div
					v-for="invite in group.invites"
					v-bind:key="invite.code"
					class="invite"
					:class="{
						available: invite.status == 'available',
						pending: invite.status == 'sent',
						accepted: invite.status == 'accepted',
						expired: invite.status == 'expired',
					}"
					@click="useInvite(invite, group)"
				>
					{{ invite.joinUserName ? invite.joinUserName : invite.code }}
				</div>
				<div
					v-if="$services.hasKey('The One') || $services.hasKey('Director')"
					class="addInvites"
					@click="addInvites(group)"
				>
					Add Invites
				</div>
			</div>
		</div>

		<div class="header">Accept New Invite</div>
		<div class="group">
			<input
				class="invite-code"
				type="text"
				v-model="code"
				placeholder="Invite code"
			/>
			<button class="btn" @click="acceptInvite()">Accept Invite</button>
			<div class="info">
				<div v-if="error.length > 0" class="error">{{ error }}</div>
			</div>
		</div>

		<button
			v-if="$services.hasKey('The One') || $services.hasKey('Groupie')"
			class="goButton"
			@click="newGroup()"
		>
			Start a New Group
		</button>
		<div class="header">Your Keys</div>
		<div class="group">
			<div v-for="key in user.keys" :key="key" @click="showKey(key)">
				<li style="cursor: pointer">{{ key }}</li>
			</div>
		</div>
		<div v-if="user.role == 'admin'">
			<button
				v-if="$services.hasKey('The One')"
				class="goButton"
				@click="callJeeves()"
			>
				Call Jeeves
			</button>
			<button
				v-if="$services.hasKey('The One')"
				class="goButton"
				@click="generateChoices()"
			>
				Generate Choices
			</button>
			<div
				class="groupChoices"
				v-if="waitingChoices && waitingChoices.length > 0"
			>
				<div class="header">Choices Awaiting Results</div>
				<div
					class="smallChoice pending"
					v-for="choice in waitingChoices"
					v-bind:key="'choice:' + choice.id"
					@click="goTo(choice)"
				>
					{{ choice.name || "UH OH" }}
				</div>
			</div>
		</div>
		<button class="goButton admin" @click="signOut()">Sign Out</button>
		<div class="bottom-spacer" />
	</div>
</template>

<script>
export default {
	name: "Basic",
	data() {
		return {
			user: this.$services.me,
			groups: [],
			waitingChoices: [],
			invites: [],
			code: "",
			error: ""
		};
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.$services.getMe().then(async (me) => {
				this.user = me;
				if (this.user.role == "admin") {
					this.waitingChoices = await this.$services.getAll("choices/waiting");
				}
				this.loadGroups();
			})
		},
		loadGroups: function () {
			this.$services.getAll("groups").then((groups) => {
				this.groups = groups.map(group => {
					group["invites"] = [];
					return group;
				})
				console.log("Load the groups and they are: ", this.groups)
				this.loadInvites();
			})
				.catch(err => console.log("UH OH>>>>", err))
		},
		loadInvites: function () {
			// console.log("Groups before load invite", this.groups);
			this.groups = this.groups.map((group) => {
				if (group.ownerID == this.user.id) {
					this.$services.getAll("groups/" + group.id + "/invites").then((invites) => {
						group.invites = invites;
					});
				}
				return group;
			});
		},
		generateChoices: function () {
			this.$services.get("score/generate").then((res) => {
				console.log("Got back", res);
			})
		},
		callJeeves: function () {
			this.$services.get("score/jeeves").then((res) => {
				console.log("Got back", res);
			})
		},
		acceptInvite: async function () {
			// console.log("Accept this....", this.code);
			const res = await this.$services.acceptInvite(this.code);
			if (res.error) {
				this.error = res.error;
			} else {
				this.$router.push('/')
			}
		},
		goTo: function (choice) {
			this.$router.push(`/choice/${choice.id}`)
		},
		showKey: function (key) {
			this.$router.push(`/key-info/${key}`)
		},
		editGroup: function (group) {
			if (group.ownerID == this.user.id) {
				this.$router.push(`/edit-group/${group.id}`)
			}
		},
		useInvite: function (invite, group) {
			// console.log("TODO: Get contacts, send SMS invite", invite.code);
			const dummy = document.createElement("textarea");
			document.body.appendChild(dummy);
			dummy.value = invite.code;
			dummy.select();
			document.execCommand("copy");
			document.body.removeChild(dummy);
		},
		addInvites: function (group) {
			this.$services.addInvites(group.id).then((invites) => {
				this.loadInvites();
			});
		},
		newGroup: function () {
			this.$router.push(`/edit-group/new`)
		},
		signOut: function () {
			// console.log("SIGN OUT!!!")
			localStorage.removeItem("token");
			this.$services.enableNav = false;
			this.$router.push('login')
		}
	}
}

</script>

<style scoped>
.memberSince {
	font-style: italic;
	font-size: 16px;
	text-align: right;
	padding: 5px 5px 0px 0px;
}
.header {
	font-size: 24px;
	margin: 3px;
	color: white;
	display: block;
}
.groupList {
	font-size: 22px;
	color: black;
	display: block;
	margin: 5px 5px 10px 5px;
}
.group {
	background-color: black;
	color: white;
	border: 1px solid white;
	display: block;
	clear: all;
	padding: 3px;
	margin-bottom: 5px;
	border-radius: 3px;
	width: 95%;
}
.groupName {
	margin: 3px;
}
.invite {
	font-size: 18px;
	width: 20vw;
	min-height: 24px;
	display: inline-block;
	text-align: center;
	margin: 1px;
	vertical-align: top;
	padding: 3px;
	border-radius: 2px;
}
.addInvites {
	font-size: 18px;
	text-align: center;
	border: 1px solid #990000;
	border-radius: 2px;
	margin-top: 5px;
	padding: 3px;
	cursor: pointer;
}
.available {
	border: 1px dotted white;
	background-color: #444444;
	color: #cccccc;
}
.pending {
	border: 1px dashed white;
}
.accepted {
	border: 1px solid #00b300;
	color: #00b300;
}
.expired {
	border: 1px solid #990000;
	color: #b30000;
}
.info {
	text-align: center;
	font-size: 18px;
}

.error {
	color: red;
}

.invite-code {
	font-size: 16px;
	padding: 5px;
	text-align: center;
	margin: 5px;
	width: 100px;
	color: black;
}

.btn {
	margin: 5px;
	font-size: 18px;
	background-color: black;
	color: #6699ff;
	padding: 5px;
	border: 1px solid #6699ff;
}

.groupChoices {
	display: block;
	margin: 2px;
}
.smallChoice {
	display: inline-block;
	margin: 1px;
	height: 15vw;
	width: 21vw;
	padding: 3px;
	vertical-align: top;
	font-size: 14px;
}
.admin {
	background-color: #990000;
	color: white;
}
</style>
